import { Attributes, FC, useMemo } from "react";
import {
  Box,
  Button,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { merge } from "lodash";
import {
  Burn,
  BurnAssessmentColor,
  resolveBurnAssessmentIcon,
} from "@airmont/firefly/shared/ts/domain";
import { Button_color } from "shared-ts-mui";
import { useTranslation } from "react-i18next";

export interface BurnItemProps extends Attributes {
  burn: Burn;
  anonymizedTime: boolean;
  selected?: boolean;
  color?: Button_color;
  sx?: SxProps;
  onSelect?: (burn: Burn) => void;
}

export const BurnItem: FC<BurnItemProps> = (props) => {
  const { burn, anonymizedTime } = props;
  const { t: t_firefly_shared_domain } = useTranslation(
    "firefly-shared-ts-domain"
  );
  const selected = props.selected ?? false;
  const theme = useTheme();
  const BurnAssessmentIcon = resolveBurnAssessmentIcon(
    burn.analytics.startQuality
  );

  const burnAssessmentColor = useMemo(() => {
    return BurnAssessmentColor[burn.analytics.startQuality];
  }, [burn.analytics.startQuality]);

  const sx: SxProps = useMemo(() => {
    return merge({}, props.sx, {
      minWidth: "fit-content",
      pl: 0,
      pr: 0,
      pt: 0,
      pb: 0,
    } as SxProps);
  }, [props.sx]);

  const handleClick = () => {
    props.onSelect?.(props.burn);
  };

  return (
    <Tooltip
      title={t_firefly_shared_domain("{{BurnAssessment}} ignition", {
        BurnAssessment: t_firefly_shared_domain(
          `BurnAssessment.${burn.analytics.startQuality}`
        ),
      })}
    >
      <Button
        color={selected ? "primary" : "inherit"}
        variant={selected ? "contained" : "text"}
        sx={sx}
        onClick={handleClick}
      >
        <Stack
          direction={"column"}
          useFlexGap
          gap={1}
          sx={{
            alignItems: "center",
            pt: 0.5,
            pl: 2,
            pr: 2,
            pb: 1,
          }}
        >
          <Typography variant={"button"}>
            {anonymizedTime && `#${burn.count}`}
            {!anonymizedTime && (
              <>
                {burn.startTime.day}.
                {!anonymizedTime && burn.countOfDay != null && (
                  <span
                    style={{ fontSize: "smaller", verticalAlign: "sub" }}
                  >{`${burn.countOfDay}`}</span>
                )}
              </>
            )}
          </Typography>
          <Box
            sx={{
              borderRadius: "50%",
              padding: 0.75,
              backgroundColor: selected
                ? theme.palette.background.paper
                : undefined,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <BurnAssessmentIcon
              fontSize={"small"}
              sx={{
                color: burnAssessmentColor,
              }}
            />
          </Box>
        </Stack>
      </Button>
    </Tooltip>
  );
};
