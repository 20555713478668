import { Breadcrumbs, Tooltip, Typography } from "@mui/material";
import React from "react";
import { SxProps } from "@mui/system";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { MunicipalityDto } from "../../municipality/Municipality";
import { StreetAddressDto } from "../../address/StreetAddress";
import { PostalPlace } from "../../postalCode/PostalPlace";
import { useTranslation } from "react-i18next";
import { useSxMerge } from "shared-ts-mui";

export interface FlueBreadcrumbsProps {
  municipality: MunicipalityDto;
  streetAddress: StreetAddressDto;
  postalAddress: PostalPlace;
  flue: {
    name?: string;
  };
  hideMunicipality?: boolean;
  hidePostalAddress?: boolean;
  layout?: SizeClass;
  sx?: SxProps;
}

export const FlueBreadcrumbs = (props: FlueBreadcrumbsProps) => {
  const { t: tFireflyDomain } = useTranslation("firefly-shared-ts-domain");
  const layout = props.layout ?? SizeClass.ExtraLarge;
  const hideMunicipality = props.hideMunicipality ?? layout === "Compact";
  const hidePostalAddress = props.hidePostalAddress ?? false;

  let streetAddress = "";
  if (props.streetAddress != null) {
    streetAddress = props.streetAddress.street ?? "";
    if (props.streetAddress.houseNumber != null) {
      streetAddress += " " + props.streetAddress.houseNumber;
    }
    if (props.streetAddress.houseLetter != null) {
      streetAddress += props.streetAddress.houseLetter;
    }
    if (props.streetAddress.houseSection != null) {
      streetAddress += " - " + props.streetAddress.houseSection;
    }
  }
  const postalAddress = `${props.postalAddress?.code ?? ""} ${
    props.postalAddress?.place ?? ""
  }`;
  const flue = props.flue?.name ?? null;

  const sx = useSxMerge(props.sx, {
    ">.MuiBreadcrumbs-ol": {
      flexWrap: "nowrap",
    },
  });

  if (layout === SizeClass.Compact) {
    let compactDisplayText = streetAddress;
    if (flue != null && flue.length > 0) {
      compactDisplayText += " / " + flue;
    }
    return (
      <Typography noWrap maxWidth={"100px"}>
        {compactDisplayText}
      </Typography>
    );
  } else {
    let maxItems = 4;
    let itemsBeforeCollapse = 0;
    let itemsAfterCollapse = 2;
    if (layout === SizeClass.Medium) {
      maxItems = 2;
      itemsBeforeCollapse = 0;
      itemsAfterCollapse = 2;
    }
    return (
      <Breadcrumbs
        maxItems={maxItems}
        itemsBeforeCollapse={itemsBeforeCollapse}
        itemsAfterCollapse={itemsAfterCollapse}
        sx={sx}
      >
        {!hideMunicipality && props.municipality?.name && (
          <Typography noWrap>{props.municipality?.name}</Typography>
        )}
        {hidePostalAddress && <Typography noWrap>{postalAddress}</Typography>}
        <Typography noWrap>{streetAddress}</Typography>
        {flue != null && flue.length > 0 && (
          <Tooltip title={tFireflyDomain("Flue")} arrow>
            <Typography noWrap>{flue}</Typography>
          </Tooltip>
        )}
      </Breadcrumbs>
    );
  }
};
