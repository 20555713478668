import React, { FC, ReactNode } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import {
  SizeClass,
  useWindowWidthComparer,
} from "@airmont/shared/ts/ui/responsive";

export interface GenericBootPageProps {
  appIcon?: ReactNode;
  appName: ReactNode;
  children: ReactNode;
}

export const NavigationLessAppPage: FC<GenericBootPageProps> = (props) => {
  const widthComparer = useWindowWidthComparer();
  return (
    <Container
      className={"NavigationLessAppPage"}
      maxWidth={
        widthComparer.gte(SizeClass.Expanded)
          ? "md"
          : widthComparer.gte(SizeClass.Medium)
          ? "md"
          : "xs"
      }
      component={"main"}
      sx={{
        height: "100%",
        alignSelf: "center",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "center",
      }}
    >
      <Stack direction={"column"} useFlexGap gap={1}>
        {props.appIcon != null && props.appIcon}
        {React.isValidElement(props.appName) && props.appName}
        {typeof props.appName === "string" && (
          <Typography
            align={"center"}
            variant={
              widthComparer.gte(SizeClass.Expanded)
                ? "h1"
                : widthComparer.gte(SizeClass.Medium)
                ? "h2"
                : "h3"
            }
          >
            {props.appName}
          </Typography>
        )}
        <Box
          sx={{
            pt: 1,
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              pl: widthComparer.gte(SizeClass.Expanded)
                ? 30
                : widthComparer.gte(SizeClass.Medium)
                ? 20
                : 5,
            }}
          >
            {props.children}
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};
