import {
  Container,
  CircularProgress,
  Stack,
  SxProps,
  Toolbar,
  Typography,
  Button,
  Alert,
} from "@mui/material";
import { Formik, FormikErrors } from "formik";
import { FormikHelpers } from "formik/dist/types";
import { PropertiesCard } from "@airmont/shared/ts/ui/properties-card";
import React, { FC, useEffect, useState } from "react";
import { useSxMerge } from "shared-ts-mui";
import { useTranslation } from "react-i18next";
import { useCriiptoVerify } from "@criipto/verify-react";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { useUserRegistrationDao } from "./useUserRegistrationDao";
import { UserRegisterRequest } from "./UserRegisterRequest";
import { LoadingButton } from "@mui/lab";
import { RegisteringUserDto } from "./RegisteringUser";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";
import { useComponentSizeFromDense } from "@airmont/shared/ts/ui/responsive";
import { isValidEmail } from "@airmont/shared/ts/ui/identity";
import { ConsentBurnTimeIsSavedProperty } from "@airmont/firefly/my-chimney/ts/pages";
import { Property } from "shared-ts-property";

type RegisterUserFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  consentBurnTimeIsSaved: boolean;
};

const initialRegisterUserFormValues: RegisterUserFormValues = {
  email: "",
  password: "",
  confirmPassword: "",
  consentBurnTimeIsSaved: false,
};

export interface RegisterUserProcessProps {
  sx?: SxProps;
}

export const RegisterUserProcess: FC<RegisterUserProcessProps> = (props) => {
  const componentSize = useComponentSizeFromDense();
  const { t } = useTranslation("app");
  const { t: tSharedIdentity } = useTranslation("shared-ts-ui-identity");
  const {
    result: criiptoLoginResult,
    isLoading: isTokenLoading,
    isInitializing: isTokenInitializing,
  } = useCriiptoVerify();
  const token = (criiptoLoginResult as any)?.id_token;
  const userRegistrationDao = useUserRegistrationDao(token);

  const [registeringUser, setRegisteringUser] = useState<
    RegisteringUserDto | undefined
  >(undefined);

  const [initialFormValues, setInitialFormValues] =
    useState<RegisterUserFormValues>(initialRegisterUserFormValues);

  const [registrationSuccess, setRegistrationSuccess] = useState<
    boolean | undefined
  >(undefined);

  const [getRegisteringUserFailed, setGetRegisteringUserFailed] =
    useState<boolean>(false);

  const [getRegisteringUserRetryCount, setGetRegisteringUserRetryCount] =
    useState<number>(0);

  const [isFetchingRegisteringUser, setIsFetchingRegisteringUser] =
    useState<boolean>(false);

  useEffect(
    function getRegisteringUser() {
      const doGetRegisteringUser = async () => {
        if (userRegistrationDao) {
          try {
            setIsFetchingRegisteringUser(true);
            const registeringUser =
              await userRegistrationDao.getRegisteringUser();
            setIsFetchingRegisteringUser(false);
            setRegisteringUser(registeringUser);
            setInitialFormValues({
              email: registeringUser.email ?? "",
              password: "",
              confirmPassword: "",
              consentBurnTimeIsSaved: false,
            });
          } catch (e) {
            setIsFetchingRegisteringUser(false);
            setGetRegisteringUserFailed(true);
          }
        }
      };
      doGetRegisteringUser();
    },
    [getRegisteringUserRetryCount, userRegistrationDao]
  );

  const handleValidate = (
    values: RegisterUserFormValues
  ): FormikErrors<RegisterUserFormValues> => {
    const errors: FormikErrors<RegisterUserFormValues> = {};

    if (isValidEmail(values.email)) {
      errors.email = tSharedIdentity("Invalid e-mail address");
    }
    if (values.password.isBlank()) {
      errors.password = tSharedIdentity("Required");
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = tSharedIdentity(
        "Password and confirmation of password must match"
      );
    }
    return errors;
  };

  const handleSubmit = async (
    values: RegisterUserFormValues,
    formikHelpers: FormikHelpers<RegisterUserFormValues>
  ) => {
    if (registeringUser == null) {
      throw new IllegalStateError("registeringUser was null");
    }
    const request: UserRegisterRequest = {
      email: values.email,
      password: values.password,
      consentBurnTimeIsSaved: values.consentBurnTimeIsSaved,
    };
    try {
      const mcUser = await userRegistrationDao?.registerUser(request);
      formikHelpers.setSubmitting(false);
      if (mcUser !== undefined) {
        setRegistrationSuccess(true);
      } else {
        // TODO: What to do ???
      }
    } catch (e) {
      setRegistrationSuccess(false);
    }
  };

  const handleRetryGetRegisteredUser = () => {
    setGetRegisteringUserRetryCount((prevState) => prevState + 1);
  };

  const handleTokenExpiredTryAgain = () => {
    window.location.href = "/";
  };

  const sx = useSxMerge(props.sx, {
    flex: 1,
    minHeight: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  });

  return (
    <Container
      maxWidth={"xs"}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Stack
        className={"AppLogin"}
        direction={"column"}
        useFlexGap
        gap={2}
        sx={sx}
      >
        <AirmontLogoImg />
        <Typography variant={"h2"} align={"center"} sx={{ mb: 2 }}>
          {t("My Chimney")}
        </Typography>
        {(isTokenInitializing ||
          isTokenLoading ||
          isFetchingRegisteringUser) && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress color="inherit" />
          </div>
        )}
        <>
          {token !== undefined && (
            <>
              {registeringUser && (
                <Typography variant={"h3"} align={"center"} sx={{ mb: 2 }}>
                  {t("Welcome") +
                    " " +
                    registeringUser?.firstName +
                    " " +
                    registeringUser?.lastName}
                </Typography>
              )}
              {registrationSuccess !== true && (
                <Formik
                  initialValues={initialFormValues}
                  validate={handleValidate}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({
                    dirty,
                    values,
                    submitForm,
                    isSubmitting,
                    setFieldValue,
                    handleBlur,
                    touched,
                    errors,
                    isValid,
                  }) => {
                    const handleSubmitClick = () => {
                      submitForm();
                    };

                    return (
                      <Stack direction={"column"}>
                        <PropertiesCard
                          header={{
                            title: tSharedIdentity(
                              "Register yourself as a user"
                            ),
                          }}
                          sx={{
                            justifyContent: "center",
                          }}
                        >
                          <Property
                            name={"email"}
                            label={tSharedIdentity("E-mail")}
                            value={values.email}
                            onFormikFieldValueChange={setFieldValue}
                            inputProps={{
                              autocomplete: "off",
                            }}
                            mode={"edit"}
                            fullWidth
                            helperText={errors.email}
                            error={errors.email != null}
                          />
                          <Property
                            name={"password"}
                            label={tSharedIdentity("Password")}
                            value={values.password}
                            type={"password"}
                            onFormikFieldValueChange={setFieldValue}
                            inputProps={{
                              autocomplete: "off",
                            }}
                            mode={"edit"}
                            fullWidth
                            helperText={touched.password && errors.password}
                            error={touched.password && errors.password != null}
                          />
                          <Property
                            name={"confirmPassword"}
                            label={tSharedIdentity("Confirm Password")}
                            value={values.confirmPassword}
                            type={"password"}
                            onFormikFieldValueChange={setFieldValue}
                            onBlur={handleBlur}
                            inputProps={{
                              autocomplete: "off",
                            }}
                            mode={"edit"}
                            fullWidth
                            helperText={
                              touched.confirmPassword && errors.confirmPassword
                            }
                            error={
                              touched.confirmPassword &&
                              errors.confirmPassword != null
                            }
                          />
                          <ConsentBurnTimeIsSavedProperty
                            value={values.consentBurnTimeIsSaved}
                            onChange={setFieldValue}
                          />
                        </PropertiesCard>
                        <Toolbar>
                          <LoadingButton
                            color={"secondary"}
                            variant={"outlined"}
                            disabled={!dirty || !isValid}
                            loading={isSubmitting}
                            onClick={handleSubmitClick}
                          >
                            {tSharedIdentity("Register")}
                          </LoadingButton>
                        </Toolbar>
                      </Stack>
                    );
                  }}
                </Formik>
              )}
            </>
          )}
          {registrationSuccess === true && (
            <>
              <Typography variant={"h5"} align={"center"}>
                {tSharedIdentity("Your user is registered")}
              </Typography>
              <Typography variant={"h6"} align={"center"}>
                {tSharedIdentity(
                  "Confirmation of your e-email is needed before you can login"
                )}
                {". "}
                {tSharedIdentity(
                  "Please check your e-mail for further instructions"
                )}
              </Typography>
              <Alert severity={"info"} variant={"outlined"}>
                {tSharedIdentity("You can safely close this page")}
              </Alert>
            </>
          )}
          {getRegisteringUserFailed && (
            <>
              <Typography variant={"h5"} align={"center"}>
                {`${tSharedIdentity(
                  "Sorry, we could not retrieve your user details"
                )}. ${t("Please try again or contact support")}.`}
              </Typography>
              <Button
                variant={"contained"}
                size={componentSize}
                onClick={handleRetryGetRegisteredUser}
                sx={{
                  alignSelf: "center",
                }}
              >
                {tSharedIdentity("Try again")}
              </Button>
            </>
          )}
          {registrationSuccess === false && (
            <Typography variant={"h3"} align={"center"}>
              {tSharedIdentity("User registration failed")}
            </Typography>
          )}
          {token === undefined && !(isTokenInitializing || isTokenLoading) && (
            <>
              <Alert severity={"error"} variant={"outlined"}>
                {tSharedIdentity("Token for registration has expired")}
              </Alert>
              <Stack
                direction={"row"}
                sx={{ width: "100%", justifyContent: "space-evenly" }}
              >
                <Button
                  size={componentSize}
                  variant={"contained"}
                  onClick={handleTokenExpiredTryAgain}
                >
                  {tSharedIdentity("Try again")}
                </Button>
              </Stack>
            </>
          )}
        </>
      </Stack>
    </Container>
  );
};
