import React, { ReactNode, useMemo } from "react";
import { EnvironmentContext, IEnvironmentContext } from "./EnvironmentContext";
import * as Sentry from "@sentry/react";
import {
  CustomerEnvironment,
  CustomerEnvironmentId,
} from "./CustomerEnvironment";
import {
  ReadOnlyUserSettings,
  StringSetting,
  useUserSettingWithDefault,
} from "@airmont/shared/ts/utils/user-settings";
import { UserSettingName } from "./UserSettingName";

export interface EnvironmentProviderProps {
  initialUserSettings?: ReadOnlyUserSettings;
  environments: Array<CustomerEnvironment>;
  children?: ReactNode;
}

export const EnvironmentProvider = (props: EnvironmentProviderProps) => {
  if (props.environments.length === 0) {
    throw new Error("EnvironmentProvider require environments");
  }
  const initialEnvironment =
    props.initialUserSettings?.readString(UserSettingName);
  const [environmentId, setEnvironmentId] = useUserSettingWithDefault<
    CustomerEnvironmentId | undefined
  >(
    UserSettingName,
    StringSetting,
    initialEnvironment ?? props.environments[0].id
  );

  const environment = useMemo(
    () => props.environments.find((it) => it.id === environmentId),
    [environmentId, props.environments]
  );
  const _setEnvironment = (value: CustomerEnvironmentId) => {
    const environmentExists = props.environments.some((it) => it.id === value);
    let environment: CustomerEnvironmentId = value;
    if (!environmentExists) {
      environment = props.environments[0].id;
    }

    setEnvironmentId(environment);

    Sentry.setTags({
      customerEnvironment: environment,
    });
  };

  const value: IEnvironmentContext = useMemo(() => {
    return {
      environmentId: environmentId,
      environment: environment,
      environmentList: props.environments,
      setEnvironment: _setEnvironment,
    };
  }, [environmentId, environment, props.environments, _setEnvironment]);
  return (
    <EnvironmentContext.Provider value={value}>
      {props.children}
    </EnvironmentContext.Provider>
  );
};
