import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React, { StrictMode, useEffect } from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./app/App";
import { boot } from "./app/boot/boot";
import { AppBoot, BootPage } from "@airmont/shared/ts/ui/app-boot";
import {
  AppThemeInitializer,
  AppThemeProvider,
} from "@airmont/shared/ts/ui/app-theme-provider";
import { darkTheme } from "./app/theme/darkTheme";
import { lightTheme } from "./app/theme/lightTheme";
import { I18NextProvider } from "@airmont/shared/ts/utils/i18n";
import { languages } from "./languages";
import {
  ServerSettingsStorage,
  UserSettingsProvider,
} from "@airmont/shared/ts/utils/user-settings";
import { MuiLocalizationProvider } from "shared-ts-mui";
import { AppInfoProvider } from "shared-ts-app-info";
import { appInfo } from "./appInfo";
import i18next from "i18next";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { WindowSizeProvider } from "@airmont/shared/ts/ui/responsive";
import { Login } from "./app/Login";
import { Box } from "@mui/material";
import { SystemDao } from "@airmont/firefly/my-chimney/ts/system";
import { LoginProvider } from "@airmont/shared/ts/ui/identity";
import { McUser, McUserDto } from "firefly/my-chimney/ts/user";
import { AirmontLogoImg } from "shared-ts-ui-airmont";
import { ConfirmUser } from "./app/confirmUser/ConfirmUser";
import { SetNewPassword } from "./app/SetNewPassword";
import { AcceptUserInvitation } from "./app/acceptUserInvitation/AcceptUserInvitation";
import { RegisterUser } from "./app/registerUser/RegisterUser";
import { PrivacyPolicyPage } from "@airmont/firefly/my-chimney/ts/pages";
import { PostHogIdentifier, PostHogInitializer } from "shared-ts-posthog";
import { LanguageUserSettingProvider } from "shared-ts-language";
import { LocaleProvider } from "shared-ts-locale";

const hostnameSplitted = window.location.hostname.split(".");
const environment =
  hostnameSplitted.length > 0 ? hostnameSplitted[0] : window.location.hostname;
const isLocalhost = environment === "localhost";
const isDevelopmentEnvironment =
  environment === "localhost" ||
  environment === "my-chimney-dev" ||
  environment === "my-chimney-qa";
const enableSentry = !isLocalhost;
const enablePosthog = !isLocalhost;

const userSettingsStorage = new ServerSettingsStorage();

Sentry.init({
  enabled: enableSentry,
  dsn: "https://7c2637aa9965efd42f3841702d9b548d@o4504604658761728.ingest.sentry.io/4506517905801216",
  environment: environment,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: isDevelopmentEnvironment ? 1 : 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const checkAuthenticationProvider = () => {
  return new SystemDao().checkAuthentication;
};

root.render(
  <StrictMode>
    <PostHogInitializer
      enabled={enablePosthog}
      apiKey={"phc_VbxRV3Y7anFGuiq4CpwrJ6p9ezlYfEU1ZrNQk29gUF3"}
      options={{
        api_host: "https://eu.i.posthog.com",
      }}
    >
      <AppThemeProvider
        initialTheme={
          window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light"
        }
        themes={{
          dark: darkTheme,
          light: lightTheme,
        }}
      >
        <WindowSizeProvider>
          <AppInfoProvider appInfo={appInfo}>
            <I18NextProvider
              language={localStorage.getItem("ff-mc::language") ?? "nb-NO"}
              languages={languages}
              fallbackLng={{
                "en-US": ["en"],
                "nb-NO": ["no", "en"],
                "nn-NO": ["no", "en"],
              }}
              resources={[
                {
                  namespace: "app",
                  loader: import("./.i18n"),
                },
                {
                  namespace: "firefly-my-chimney-ts-pages",
                  loader: import("@airmont/firefly/my-chimney/ts/pages"),
                },
                {
                  namespace: "firefly-my-chimney-ts-building",
                  loader: import("@airmont/firefly/my-chimney/ts/building"),
                },
                {
                  namespace: "firefly-shared-ts-domain",
                  loader: import("@airmont/firefly/shared/ts/domain"),
                },
                {
                  namespace: "shared-ts-ui-app-boot",
                  loader: import("@airmont/shared/ts/ui/app-boot"),
                },
                {
                  namespace: "shared-ts-ui-identity",
                  loader: import("@airmont/shared/ts/ui/identity"),
                },
                {
                  namespace: "shared-ts-ui-timeframe",
                  loader: import("@airmont/shared/ts/ui/timeframe"),
                },
                {
                  namespace: "shared-ts-release",
                  loader: import("shared-ts-release"),
                },
                {
                  namespace: "shared-ts-app-info",
                  loader: import("shared-ts-app-info"),
                },
                {
                  namespace: "shared-ts-language",
                  loader: import("shared-ts-language"),
                },
                {
                  namespace: "shared-ts-mui",
                  loader: import("shared-ts-mui"),
                },
              ]}
              debug={true}
            >
              <LoginProvider<McUserDto>
                loginComponent={(props) => (
                  <Login
                    {...props}
                    sx={{
                      height: "100%",
                    }}
                  />
                )}
                privacyPolicy={{
                  path: "/privacy-policy",
                  component: () => (
                    <PrivacyPolicyPage
                      sx={{
                        height: "100%",
                      }}
                    />
                  ),
                }}
                registerUser={{
                  path: "/register-user",
                  component: () => (
                    <RegisterUser
                      sx={{
                        height: "100%",
                      }}
                    />
                  ),
                }}
                confirmUser={{
                  path: "/confirm-user",
                  component: () => (
                    <ConfirmUser
                      sx={{
                        height: "100%",
                      }}
                    />
                  ),
                }}
                acceptUserInvitation={{
                  path: "/accept-user-invitation",
                  component: () => (
                    <AcceptUserInvitation
                      sx={{
                        height: "100%",
                      }}
                    />
                  ),
                }}
                resetPassword={{
                  path: "/set-new-password",
                  component: () => (
                    <SetNewPassword
                      sx={{
                        height: "100%",
                      }}
                    />
                  ),
                }}
                authenticationCheck={checkAuthenticationProvider()}
                userMapper={(userDto) => new McUser(userDto)}
              >
                {(user) => {
                  Sentry.setUser({
                    id: user.id,
                    username: user.name,
                    email: user.email,
                  });
                  return (
                    <PostHogIdentifier enabled={enablePosthog} user={user}>
                      <UserSettingsProvider storage={userSettingsStorage}>
                        <LanguageUserSettingProvider defaultLanguage={"nb-NO"}>
                          {(language) => {
                            return (
                              <AppBoot
                                boot={boot}
                                bootPage={({ messages, error }) => (
                                  <BootPage
                                    appIcon={<AirmontLogoImg />}
                                    appName={i18next.t("My Chimney", {
                                      ns: "app",
                                    })}
                                    messages={messages}
                                    error={error}
                                  />
                                )}
                                app={(bootResult) => {
                                  return (
                                    <LocaleProvider locale={language}>
                                      <MuiLocalizationProvider
                                        language={language}
                                      >
                                        <AppThemeInitializer>
                                          <App bootResult={bootResult} />
                                        </AppThemeInitializer>
                                      </MuiLocalizationProvider>
                                    </LocaleProvider>
                                  );
                                }}
                                appWhenUnauthenticated={() => {
                                  return <Box>Should not hapen</Box>;
                                }}
                              />
                            );
                          }}
                        </LanguageUserSettingProvider>
                      </UserSettingsProvider>
                    </PostHogIdentifier>
                  );
                }}
              </LoginProvider>
            </I18NextProvider>
          </AppInfoProvider>
        </WindowSizeProvider>
      </AppThemeProvider>
    </PostHogInitializer>
  </StrictMode>
);
