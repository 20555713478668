import React, { ReactNode, useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import styled from "styled-components";
import { BootResult } from "./BootResult";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BootFunction } from "./BootFunction";
import { UnauthenticatedError } from "./UnauthenticatedError";

const queryClient = new QueryClient();
const StyledLayout = styled(Box)<{ $spacing: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  > main {
    flex: 1;
    min-height: 0;
    min-width: 0;
    margin: ${(props) => props.$spacing};
  }
`;

export interface AppBootProps {
  children?: (bootResult: BootResult) => ReactNode;
  boot: BootFunction;
  bootPage: (args: {
    messages: Array<string>;
    error?: Error;
  }) => React.ReactNode;
  app: (bootResult: BootResult) => ReactNode;
  appWhenUnauthenticated?: () => ReactNode;
}

export const AppBoot = (props: AppBootProps) => {
  const theme = useTheme();
  const [booting, setBooting] = useState<boolean>(true);
  const [bootMessages, setBootMessages] = useState<Array<string>>([]);
  const [bootResult, setBootResult] = useState<BootResult | null>(null);
  const [bootError, setBootError] = useState<Error | undefined>(undefined);
  const [unauthenticated, setUnauthenticated] = useState<boolean>(false);

  //console.log("**********************************************");
  //console.log(" AppBoot *************************************");
  //console.log("**********************************************");
  //console.log(" booting: " + booting);
  //console.log(" bootResult: ", bootResult);
  //console.log(" bootError: ", bootError);

  const handleBootMessage = (message: string) => {
    setBootMessages((prevState) => [...prevState, message]);
  };

  const handleBootError = (error: Error) => {
    setBootError(error);
  };

  useEffect(() => {
    const runBoot = async () => {
      try {
        setBooting(true);
        const bootResult = await props.boot({
          onMessage: handleBootMessage,
          onError: handleBootError,
        });
        setBootResult(bootResult);
        setBooting(false);
      } catch (e) {
        if (e instanceof UnauthenticatedError) {
          setUnauthenticated(true);
          setBooting(false);
        } else {
          console.error("Error during booting: ", e);
          setBootError(e as Error);
        }
      }
    };
    runBoot();

    return () => {
      setBooting(false);
      setBootError(undefined);
      setBootMessages([]);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <>
        {booting &&
          props.bootPage({ messages: bootMessages, error: bootError })}
        {!booting &&
          bootResult?.isAuthenticated === true &&
          props.app?.(bootResult)}
        {!booting && unauthenticated && props.appWhenUnauthenticated?.()}
      </>
    </QueryClientProvider>
  );
};
