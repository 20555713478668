import React, { FC, useMemo, useRef } from "react";
import { ListItemIcon, ListItemText, MenuItem, SxProps } from "@mui/material";
import { Action } from "./Action";
import { Layout } from "./Layout";
import { LayoutDirection } from "./LayoutDirection";
import { isSelectableAction } from "./SelectableAction";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useClassName } from "./useResolveClassName";
import { ItemVariant } from "./ItemVariant";
import { useSxMerge } from "shared-ts-mui";
import { useResolveItemColors } from "./useResolveItemColors";
import { useIsItemOverflowing } from "./useIsItemOverflowing";
import { useResolveItemName } from "./useResolveItemName";

export interface ActionMenuItemProps {
  item: Action;
  variant?: ItemVariant;
  layout: Layout;
  layoutDirection?: LayoutDirection;
  hideSelection?: boolean;
  hideLabel?: boolean;
  sx?: SxProps;
}
export const ActionMenuItem: FC<ActionMenuItemProps> = (props) => {
  const { item } = props;
  const ref = useRef<HTMLLIElement>(null);
  const name = useResolveItemName(item, ref.current);
  const hideLabel = props.hideLabel ?? false;
  const hideSelection = props.hideSelection ?? false;
  const selected = isSelectableAction(item) ? item.selected : undefined;
  const selectable = selected !== undefined;

  const classNameRoot = useClassName(props, [
    "Item",
    "Action",
    "ActionMenuItem",
  ]);

  const {
    onContainerColorWhenSelected,
    containerColorWhenSelected,
    containerColorOnHover,
  } = useResolveItemColors();

  const handleClick = () => {
    item.onExecute(item);
  };

  const sx = useSxMerge(props.sx, {
    ">.MuiListItemText-root": {
      ">.MuiTypography-root": {
        fontSize: "inherit",
        textAlign: "inherit",
      },
    },
    "&.layout-medium": {
      fontSize: "0.75rem",
      "&.direction-column": {
        ".MuiListItemIcon-root": {
          height: "32px",
          width: "56px",
        },
      },
    },
    "&.layout-compact": {
      fontSize: "0.75rem",
      "&.direction-column": {
        ".MuiListItemIcon-root": {
          height: "32px",
          width: "56px",
        },
      },
    },
    "&.direction-row": {
      textAlign: "left",
      ".MuiListItemText-root": {
        minHeight: "20px",
      },
      "&:hover": {
        backgroundColor: containerColorOnHover,
      },
    },
    "&.direction-column": {
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
      gap: "4px",
      ".MuiListItemIcon-root": {
        minWidth: "unset",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "15px",
      },
      "&.selected": {
        backgroundColor: "unset",
        ".MuiListItemIcon-root": {
          backgroundColor: containerColorWhenSelected,
          color: onContainerColorWhenSelected,
        },
      },
      "&:hover": {
        backgroundColor: "unset",
        ".MuiListItemIcon-root": {
          backgroundColor: containerColorOnHover,
        },
      },
    },
  });

  return (
    <MenuItem ref={ref} onClick={handleClick} className={classNameRoot} sx={sx}>
      {selectable && !hideSelection && selected && (
        <ListItemIcon className={"CheckIcon"}>
          <CheckRoundedIcon fontSize={"small"} />
        </ListItemIcon>
      )}
      {item.icon != null && (
        <ListItemIcon>
          {React.cloneElement(item.icon, {
            fontSize: "small",
          })}
        </ListItemIcon>
      )}
      {!hideLabel && (
        <ListItemText
          inset={selectable && !hideSelection && !selected}
          primary={name}
        />
      )}
    </MenuItem>
  );
};
