import React, { FC } from "react";
import { AppPage } from "@airmont/shared/ts/ui/app-page";
import {
  Box,
  Card,
  CardContent,
  Container,
  Link,
  List,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import { ListItem } from "./ListItem";
import { TipsIcon } from "./TipsIcon";
import { EnvironmentIcon } from "./EnvironmentIcon";
import { SavingEnergyIcon } from "./SavingEnergyIcon";
import { ActionIcon } from "./ActionIcon";
import { WarningIcon } from "./WarningIcon";

export const GuidancePage: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation("firefly-my-chimney-ts-pages");
  return (
    <AppPage
      $key={"GuidancePage"}
      name={t("Guide")}
      icon={LocalLibraryRoundedIcon}
      mainPaneProps={{
        scrollableContentVertically: true,
      }}
      mainPane={
        <Container
          sx={{
            ul: {
              "padding-inline-start": theme.spacing(3),
            },
            ".MuiTypography-h2": {
              mt: 2,
              mb: 2,
            },
            ".MuiTypography-h3": {
              mt: 3,
              mb: 1,
            },
            ".MuiTypography-body1": {
              m: 0.5,
            },
            ".MuiAlert-root": {
              mt: 1,
              mb: 1,
              ml: 2,
              mr: 1,
            },
          }}
        >
          <Typography variant={"h2"} id={"ignition"}>
            {t("Ignition")}
          </Typography>
          <Typography>{t("Guide.This is general advice...")}</Typography>
          <Stack
            direction={"column"}
            useFlexGap
            gap={2}
            sx={{
              pt: 1,
              pl: 1,
              pr: 1,
              "&& .MuiTypography-h3": { mt: 0 },
              "&& ul": { paddingInlineStart: 1 },
              "& ul>li:last-child": { pb: 0 },
            }}
          >
            <Card id={"use-dry-wood"}>
              <CardContent>
                <Typography variant={"h3"}>
                  {t("Guide.Use dry wood")}
                </Typography>
                <ul>
                  <ListItem icon={<EnvironmentIcon />}>
                    {t("Guide.Use dry wood..1")}
                  </ListItem>
                  <ListItem icon={<SavingEnergyIcon />}>
                    {t("Guide.Use dry wood..2")}
                  </ListItem>
                  <ListItem icon={<TipsIcon />}>
                    {t("Guide.Use dry wood..3")}
                  </ListItem>
                </ul>
              </CardContent>
            </Card>
            <Card id={"ensure-good-access-to-air"}>
              <CardContent>
                <Typography variant={"h3"}>
                  {t("Guide.Ensure good access to air")}
                </Typography>
                <ul>
                  <ListItem icon={<EnvironmentIcon />}>
                    {t("Guide.Ensure good access to air..1")}
                  </ListItem>
                  <ListItem icon={<ActionIcon />}>
                    {t("Guide.Ensure good access to air..2")}
                  </ListItem>
                  <ListItem icon={<TipsIcon />}>
                    {t("Guide.Ensure good access to air..3")}
                  </ListItem>
                  <ListItem icon={<WarningIcon />}>
                    {t("Guide.Ensure good access to air..4")}
                  </ListItem>
                </ul>
              </CardContent>
            </Card>
            <Card id={"build-the-fire-correctly"}>
              <CardContent>
                <Typography variant={"h3"}>
                  {t("Guide.Build the fire correctly")}
                </Typography>
                <ul>
                  <ListItem icon={<ActionIcon />}>
                    {t("Guide.Build the fire correctly..1")}
                  </ListItem>
                  <ListItem icon={<TipsIcon />}>
                    {t("Guide.Build the fire correctly..2")}
                  </ListItem>
                  <ListItem icon={<WarningIcon />}>
                    {t("Guide.Build the fire correctly..3")}
                  </ListItem>
                  <ListItem icon={<WarningIcon />}>
                    {t("Guide.Build the fire correctly..4")}
                  </ListItem>
                </ul>
              </CardContent>
            </Card>
            <Card>
              <CardContent>
                <Typography variant={"h3"} id={"light-up-from-the-top"}>
                  {t("Guide.Light up from the top")}
                </Typography>
                <ul>
                  <ListItem icon={<EnvironmentIcon />}>
                    {t("Guide.Light up from the top..1")}
                  </ListItem>
                  <ListItem icon={<TipsIcon />}>
                    {t("Guide.Light up from the top..2")}
                  </ListItem>
                </ul>
              </CardContent>
            </Card>
          </Stack>
        </Container>
      }
      supportingPaneProps={{
        label: t("Table of Contents"),
      }}
      supportingPane={
        <Box
          sx={{
            a: {
              textDecoration: "none",
            },
            ul: {
              "padding-inline-start": theme.spacing(3),
            },
            "ul > li > a": {
              color: "text.primary",
            },
          }}
        >
          <ul>
            <Typography component={"li"}>
              <Link component={RouterLink} to={"#ignition"} reloadDocument>
                {t("Ignition")}
              </Link>
            </Typography>
            <ul>
              <Typography component={"li"}>
                <Link
                  component={RouterLink}
                  to={"#use-dry-wood"}
                  reloadDocument
                >
                  {t("Guide.Use dry wood")}
                </Link>
              </Typography>
              <Typography component={"li"}>
                <Link
                  component={RouterLink}
                  to={"#ensure-good-access-to-air"}
                  reloadDocument
                >
                  {t("Guide.Ensure good access to air")}
                </Link>
              </Typography>
              <Typography component={"li"}>
                <Link
                  component={RouterLink}
                  to={"#build-the-fire-correctly"}
                  reloadDocument
                >
                  {t("Guide.Build the fire correctly")}
                </Link>
              </Typography>
              <Typography component={"li"}>
                <Link
                  component={RouterLink}
                  to={"#light-up-from-the-top"}
                  reloadDocument
                >
                  {t("Guide.Light up from the top")}
                </Link>
              </Typography>
            </ul>
          </ul>
          <List subheader={t("Legend") + ": "}>
            <ListItem icon={<EnvironmentIcon />}>
              {t("Guide.Good for the environment")}
            </ListItem>
            <ListItem icon={<SavingEnergyIcon />}>
              {t("Guide.Good for the environment")}
            </ListItem>
            <ListItem icon={<TipsIcon />}>{t("Guide.Tips")}</ListItem>
            <ListItem icon={<WarningIcon />}>{t("Guide.Warning")}</ListItem>
            <ListItem icon={<ActionIcon />}>{t("Guide.Action")}</ListItem>
          </List>
        </Box>
      }
    />
  );
};
